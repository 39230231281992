<template>
  <component
    :is="rootComponent"
    class="d-md-flex align-items-center p-3 text-decoration-none"
    :class="{ 'hover-text-primary': to }"
    :to="to"
  >
    <div
      class="text-primary px-2"
      style="width: 60px"
    >
      <small>{{ time }}</small>
    </div>

    <div class="flex-grow-1 px-2 text-start lh-sm">
      <div class="text-body fw-bold hover-target">
        {{ match.home.name }}
        -
        {{ match.away.name }}
      </div>

      <small class="text-muted align-middle">
        <country-flag
          class="me-1"
          :country="flag"
          height="16"
          width="16"
        />

        {{ match.competition.name }}
      </small>
    </div>

    <div
      class="px-2 text-lg-end"
      :class="{
        'mt-2': hasChannels,
        'mt-md-0': hasChannels,
      }"
    >
      <channel-item
        v-for="channel in match.channels"
        :key="channel.key"
        :channel="channel"
        class="py-1 text-nowrap text-body text-start"
      />
    </div>
  </component>
</template>

<script>
import { defineComponent } from 'vue';
import IconPlay from '~/components/icons/IconPlay.vue';
import ChannelItem from '~/components/common/ChannelItem.vue';
import { DATE_HH_mm } from '~/config/global';
import CountryFlag from '~/components/common/CountryFlag.vue';

export default defineComponent({
  name: 'HomepageMatch',

  components: {
    CountryFlag,
    ChannelItem,
    IconPlay,
  },

  props: {
    match: {
      required: true,
      type: Object,
    },
  },

  computed: {
    rootComponent() {
      if (!this.match.page) {
        return 'div';
      }

      return resolveComponent('nuxt-link');
    },

    to() {
      const { page } = this.match;

      if (!page) {
        return null;
      }

      return this.localePath({ name: 'match-slug', params: { slug: page.slug } });
    },

    time() {
      return this.$dateFns.format(this.match.start_time, DATE_HH_mm);
    },

    hasChannels() {
      return this.match.channels.length > 0;
    },

    flag() {
      const { competition } = this.match;

      if (!competition) {
        return null;
      }

      const { category } = competition;

      if (!category) {
        return null;
      }

      return category.country_code;
    },
  },
});
</script>
