<template>
  <div>
    <div class="mb-2 pb-2 overflow-x-auto text-nowrap">
      <u-button color="primary">
        {{ $t('homepage.button.today_and_week') }}
      </u-button>

      <span class="text-muted opacity-25 mx-1">
        |
      </span>

      <u-button
        v-for="{ date, text, disabled } in dates"
        :key="date"
        class="me-2"
        color="light"
        :disabled="disabled"
        @click="!disabled && scrollTo(date)"
      >
        {{ text }}
      </u-button>
    </div>

    <day-matches-card
      v-for="group in groupedMatches"
      :key="group.header"
      :ref="(el) => days[group.date] = el"
      class="mb-3"
      :header="group.header"
      :matches="group.matches"
    />
  </div>
</template>

<script>
import { addDays, set } from 'date-fns';
import DayMatchesCard from '~/components/homepage/DayMatchesCard.vue';
import UButton from '~/components/uikit/UButton.vue';
import { DATE_d_LLL, DATE_d_LLLL, DATE_YYYY_MM_DD, HOMEPAGE_DAYS_MORE } from '~/config/global';
import { groupBy } from '~/utils/groupBy';

export default {
  name: 'GroupedMatches',

  components: {
    UButton,
    DayMatchesCard,
  },

  props: {
    matches: {
      required: true,
      type: Array,
    },
    daysLimit: {
      required: true,
      type: Number,
    },
  },

  setup() {
    return {
      days: ref({}),
    };
  },

  computed: {
    dates() {
      const availableDates = this.groupedMatches.map(({ date }) => date);
      const dates = [];
      const now = this.$dateFns.normalizeDate(new Date());
      const today = set(now, {
        hours: 12,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      });

      for (let i = 1; i <= this.daysLimit; i++) {
        const tmpDate = addDays(today, i);
        const date = this.$dateFns.format(tmpDate, DATE_YYYY_MM_DD);

        dates.push({
          date,
          text: this.$dateFns.format(tmpDate, DATE_d_LLL),
          disabled: !availableDates.includes(date),
        });
      }

      return dates;
    },

    groupedMatches() {
      const grouped = groupBy(
        this
          .matches
          .map(({ away_competitor, home_competitor, ...match }) => ({
            away: away_competitor,
            home: home_competitor,
            ...match,
          })),
        ({ start_time }) => this.$dateFns.format(start_time, DATE_YYYY_MM_DD),
      );

      return Object
        .entries(grouped)
        .map(([date, matches]) => ({
          date,
          header: this.prepareHeader(matches[0].start_time),
          matches,
        }));
    },
  },

  methods: {
    prepareHeader(date) {
      const formattedDate = this.$dateFns.format(date, DATE_d_LLLL);
      const formattedNow = this.$dateFns.format(new Date(), DATE_d_LLLL);

      if (formattedDate === formattedNow) {
        return this.$t('homepage.football_live_today');
      }

      return formattedDate;
    },

    scrollTo(date) {
      const $ref = this.days[date];

      if (!$ref) {
        return;
      }

      const { $el } = $ref;

      if (!$el) {
        return;
      }

      $el.scrollIntoView({ behavior: 'smooth' });
    },
  },
};
</script>
