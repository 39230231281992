<template>
  <u-card>
    <template #header>
      {{ header }}
    </template>

    <template #raw>
      <homepage-match
        v-for="(match, index) in matches"
        :key="match.id"
        class="card-content"
        :class="{ 'bg-gray-100': index % 2 === 1 }"
        :match="match"
      />
    </template>
  </u-card>
</template>

<script>
import { defineComponent } from 'vue';
import HomepageMatch from '~/components/homepage/HomepageMatch.vue';
import UCard from '~/components/uikit/UCard.vue';

export default defineComponent({
  name: 'DayMatchesCard',

  components: {
    UCard,
    HomepageMatch,
  },

  props: {
    header: {
      required: true,
      type: String,
    },
    matches: {
      required: true,
      type: Array,
    },
  },
});
</script>
