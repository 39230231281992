<template>
  <img
    :alt="`Flag ${country}`"
    height="14"
    loading="lazy"
    :src="flag"
    width="14"
  >
</template>

<script>
export default {
  name: 'CountryFlag',

  props: {
    country: {
      required: false,
      type: String,
      default: null,
    },
  },

  computed: {
    flag() {
      const { country } = this;

      if (['KOS', 'UGA'].includes(country) || !country) {
        return '/flags/WORLD.svg';
      }

      return `/flags/${country}.svg`;
    },
  },
};
</script>
