import { getValue } from './common.js';

function groupBy(array, prop) {
  return array
    .reduce((groups, item) => {
      const val = getValue(item, prop);
      if (!Object.prototype.hasOwnProperty.call(groups, val)) {
        groups[val] = [];
      }
      groups[val].push(item);
      return groups;
    }, {});
}

export { groupBy };
