<template>
  <button
    :aria-disabled="disabled"
    class="btn"
    :class="classes"
    :disabled="disabled"
    type="button"
  >
    <slot/>
  </button>
</template>

<script>
import { defineComponent } from 'vue';
import { BUTTON_COLORS, BUTTON_SIZES, COLOR_LINK } from '~/components/uikit/constants';

export default defineComponent({
  name: 'UButton',

  props: {
    color: {
      required: false,
      type: String,
      default: COLOR_LINK,
      validator(value) {
        return BUTTON_COLORS.includes(value);
      },
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    outline: {
      required: false,
      type: Boolean,
      default: false,
    },
    size: {
      required: false,
      type: String,
      default: null,
      validator(value) {
        return BUTTON_SIZES.includes(value);
      },
    },
  },

  computed: {
    classes() {
      const {
        color,
        disabled,
        outline,
        size,
      } = this;
      const classes = [];

      if (outline) {
        classes.push(`btn-outline-${color}`);
      } else {
        classes.push(`btn-${color}`);
      }

      if (size) {
        classes.push(`btn-${size}`);
      }

      if (disabled) {
        classes.push('disabled');
      }

      return classes;
    },
  },
});
</script>
